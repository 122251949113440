import React, { useContext, useEffect, useState } from "react";
import { baseUri, socketBaseUri } from "src/config/config";
import {
  _get_admin_time_zone_localStorage,
  _get_card_localStorage,
  _get_coach_from_localStorage,
  _get_consultant_from_localStorage,
  _get_consultant_id_from_localStorage,
  _get_team_type_from_localStorage,
  _get_user_from_localStorage,
  _get_graphics_from_localStorage,
  set_graphics_data_in_localStorage,
  _get_general_data_from_localStorage,
  _get_consultant_data_from_localStorage,
  _get_coach_id_from_localStorage,
  _get_project_data_from_localStorage,
  _get_dynamic_caoch_id_from_localStorage,
  _get_nav_items_from_localStorage,
  _get_coach_list_from_localStorage,
  _get_domain_data_from_localStorage,
  _get_coach_time_zone_localStorage,
  _get_default_content_from_localStorage,
  _get_defaultLangugae_from_localStorage,
  _get_navbar_content_from_localStorage,
  _get_client_navbar_content_from_localStorage,
  _get_sandbox_coach_card_localStorage,
} from "../DAL/localstorage/LocalStorage";

import io from "socket.io-client";
import { consultantProfileApi } from "src/DAL/Login/Login";
import { getDefaultNavProgram } from "src/DAL/WebsitePages/WebsiteProgrammes";
import { consultantDataApi } from "src/DAL/SiteSetting/siteSetting";
import { project_configuration_api } from "src/DAL/WebsitePages/WebsitePages";
import moment from "moment";
import { get_date_with_user_time_zone } from "src/utils/constants";
import {
  currencyApi,
  currencyApicourses,
} from "src/DAL/WebsitePages/paymentPlan";
import { get_coach_language_list } from "src/DAL/Languages/Languages";
import { useSnackbar } from "notistack";
import sidebarConfig from "src/layouts/dashboard/SidebarConfig";

const CreateContentSetting = React.createContext();
const get_admin_time_zone_localStorage = _get_admin_time_zone_localStorage();
const get_coach_time_zone_localStorage = _get_coach_time_zone_localStorage();
const get_user = _get_user_from_localStorage();
const get_card = _get_card_localStorage();
const get_sandbox_coach_card = _get_sandbox_coach_card_localStorage();
const get_consultant = _get_consultant_from_localStorage();
const get_coach = _get_coach_from_localStorage();
const get_consultant_id = _get_consultant_id_from_localStorage();
const get_team_type = _get_team_type_from_localStorage();
const coach_graphics_data = _get_graphics_from_localStorage();
const get_general_data = _get_general_data_from_localStorage();
const get_consultant_data = _get_consultant_data_from_localStorage();
const get_coach_id = _get_coach_id_from_localStorage();
const get_project_data = _get_project_data_from_localStorage();
const get_domain_data = _get_domain_data_from_localStorage();
const get_dynamic_coach = _get_dynamic_caoch_id_from_localStorage();
const get_nav_items = _get_nav_items_from_localStorage();
const get_coach_list = _get_coach_list_from_localStorage();
const get_default_content = _get_default_content_from_localStorage();
const get_navbar_content = _get_navbar_content_from_localStorage();
const get_client_navbar_content =
  _get_client_navbar_content_from_localStorage();
const get_default_language = _get_defaultLangugae_from_localStorage();
// socket-initialization
const socketConfig = {
  reconnectionDelayMax: 10000,
};

let socket = { on: () => {}, emit: () => {}, off: () => {} };
if (get_consultant_data?.coach._id) {
  // toturn off the socket
  socket = io(
    socketBaseUri + `?user_id=${get_consultant_data.coach._id}&role=coach`,
    {
      ...socketConfig,
    }
  );
}

export const useContentSetting = () => useContext(CreateContentSetting);
export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  const START_SOCKET_FORCEFULLY = (user_id) => {
    if (Object.keys(socket).length > 0) {
      // check if the connection request from same user id
      let uri = socket.io?.uri;
      let is_same_user = uri?.includes(user_id);

      // --------------------------------------------------------------
      // if socket is not connected. Just connect the socket with user id

      if (!socket.connected) {
        socket = io(
          socketBaseUri + `?user_id=${user_id}&role=coach`,
          socketConfig
        );
        setIsConnected(true);
        return;
      }

      // --------------------------------------------------------------

      // if socket is connected but upcoming user id is for new user
      // close old connection and open new connection with new id.

      if (socket.connected && !is_same_user) {
        socket.disconnect();
        socket = io(
          socketBaseUri + `?user_id=${user_id}&role=coach`,
          socketConfig
        );

        setIsConnected(true);
      }

      // --------------------------------------------------------------
    } else {
      socket = io(
        socketBaseUri + `?user_id=${user_id}&role=coach`,
        socketConfig
      );
    }
  };

  const STOP_SOCKET_FORCEFULLY = (user_id) => {
    socket.disconnect();
  };

  const [adminTimeZone, setAdminTimeZone] = useState(
    get_admin_time_zone_localStorage
  );
  const [coachTimeZone, setCoachTimeZone] = useState(
    get_coach_time_zone_localStorage
  );
  const [generalData, setGeneralData] = useState(get_general_data);
  const [consultantData, setConsultantData] = useState(get_consultant_data);
  const [dynamicCoachId, setDynamicCoachId] = useState(get_dynamic_coach);
  const [coachId, setCoachId] = useState(get_coach_id);
  const [coachGraphics, setCoachGraphics] = useState(coach_graphics_data);
  const [projectData, setProjectData] = useState(get_project_data);
  const [domainData, setDoaminData] = useState(get_domain_data);
  const [stripeKeysExist, setStripeKeysExist] = useState(false);

  const [navItems, setNavItems] = useState(get_nav_items);
  const [coachList, setCoachList] = useState(get_coach_list);

  //
  const [userInfo, setUserInfo] = useState(get_consultant_data?.coach);
  const [cardDetail, setCardDetail] = useState(get_card);
  const [sandboxCachCard, setSandboxCoachCard] = useState(
    get_sandbox_coach_card
  );

  const [consultantInfo, setConsultantInfo] = useState(get_consultant);
  const [coachInfo, setCoachInfo] = useState(get_coach);
  const [consultantId, setConsultantId] = useState(get_consultant_id);
  const [teamType, setTeamType] = useState(get_team_type);
  const [teamCoachId, setTeamCoachId] = useState(get_user?.action_id);
  const [memberType, setMemberType] = useState(get_user?.team_type);
  const [currencyList, setCurrencyList] = useState([]);
  const [courseCurrencyList, setCourseCurrencyList] = useState([]);

  const [isConnected, setIsConnected] = useState(socket.connected);

  const [openCardModal, setOpenCardModal] = useState(false);
  const [stripePaymentMode, setStripePaymentMode] = useState("live");
  const [open, setOpen] = useState(false); // state to handle notifiaction popup
  //dummy data for default content
  const [defaultContent, setDefaultContent] = useState(get_default_content);

  const [navbarContent, setNavbarContent] = useState(get_navbar_content);
  const [clientNavbarContent, setClientNavbarContent] = useState(
    get_client_navbar_content
  );

  const enqueueSnackbar = useSnackbar();
  const [defaulLanguagae, setDefaultLanguage] = useState(get_default_language);
  const [isLnaguageChange, setIsLanguageChange] = useState(false);
  const [languageListing, setLanguageListing] = useState([]);
  const [isLanguageUpdating, setIsLanguageUpdating] = useState(false);

  /* ------------------------------------------------------
  ------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const handleAdminTimeZone = (val) => {
    setAdminTimeZone(val);
  };

  const handleCoachTimeZone = (val) => {
    setCoachTimeZone(val);
  };

  const handleSetUserInfo = (val) => {
    // setUserInfo(val);
  };
  const handleSetCard = (val) => {
    setCardDetail(val);
  };
  const handleSetCoachCard = (val) => {
    setSandboxCoachCard(val);
  };
  const handleConsultantInfo = (val) => {
    // console.log(val, "sajgdsaud");
    setConsultantInfo(val);
  };
  const handleCoachList = (val) => {
    setCoachList(val);
  };
  const handleGeneralData = (val) => {
    // console.log(val, "sajgdsaud");
    setGeneralData(val);
  };
  const handleProjectData = (val) => {
    // console.log(val, "sajgdsaud");
    setProjectData(val);
  };
  const handleDomainData = (val) => {
    // console.log(val, "sajgdsaud");
    setDoaminData(val);
  };
  const handleCoachInfo = (val) => {
    setCoachInfo(val);
  };

  const handleConsultantData = (val) => {
    setConsultantData(val);
    setUserInfo(val?.coach);
  };
  const handleRemoveConsultantMetaData = () => {
    let configurationObj = {
      ...consultantData.configuration,
      meta_data: {
        ...consultantData.configuration.meta_data,
        meta_description: "",
        meta_title: "",
        favicon: {
          ...consultantData.configuration.meta_data.favicon,
          thumbnail_1: "",
        },
      },
    };
    setConsultantData((prev) => ({
      ...prev,
      configuration: configurationObj,
    }));
  };
  const handleDynamicCoach = (val) => {
    setDynamicCoachId(val);
  };
  const handleNavItmes = (val) => {
    setNavItems(val);
  };
  const handleCoachId = (val) => {
    setCoachId(val);
  };

  const handleGraphics = (val) => {
    set_graphics_data_in_localStorage(val);
    setCoachGraphics(val);
  };

  const getCoachLanguageListing = async () => {
    try {
      let result = await get_coach_language_list(coachId);
      if (result.code === 200) {
        setLanguageListing(result.languages_list);
        setIsLanguageUpdating(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Error fetching languages", { variant: "error" });
    }
  };

  useEffect(() => {
    getCoachLanguageListing();
  }, []);

  // console.log(handleCoachInfo, "gycsgfusdguifhduisfh");
  const getProfile = async () => {
    const result = await consultantProfileApi(userInfo?._id);
    if (result.code == 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result.consultant));
      localStorage.setItem(
        `plan_currency`,
        JSON.stringify(result.plan_currency)
      );
      localStorage.setItem(`admin_time_zone`, JSON.stringify(result.time_zone));
      handleSetUserInfo(result.consultant);
      handleAdminTimeZone(result.admin_time_zone);
    }
  };
  const getCardDetail = async () => {
    const postData = {
      coach_id: dynamicCoachId,
    };
    const result = await consultantDataApi(postData);

    if (result.code === 200) {
      console.log(result?.configuration, "resultOfCachCard");
      // setCoachData(result.default_setting);
      // handleCoachInfo(result.default_setting);

      if (result?.configuration && result?.configuration.coach_card) {
        localStorage.setItem(
          `card_data`,
          JSON.stringify(result?.configuration.coach_card)
        );
        setCardDetail(result?.configuration.coach_card);
      }
      if (result?.configuration && result?.configuration.sandbox_coach_card) {
        localStorage.setItem(
          `sandbox_coach_card_data`,
          JSON.stringify(result?.configuration.sandbox_coach_card)
        );
        setSandboxCoachCard(result?.configuration.sandbox_coach_card);
      }
    }
  };

  const getUserInfoDetails = async () => {
    const postData = {
      coach_id: dynamicCoachId,
    };
    const result = await consultantDataApi(postData);
    console.log(result, "configurationApiResponse");
    if (result.code === 200) {
      setIsLanguageChange(false);
      localStorage.setItem("consultantdata", JSON.stringify(result));
      localStorage.setItem(
        "coachId",
        JSON.stringify(result?.configuration.coach_id)
      );
      localStorage.setItem("coachList", JSON.stringify(result.coach_array));
      localStorage.setItem(
        `admin_time_zone`,
        JSON.stringify(result.configuration.admin_timezone)
      );
      localStorage.setItem(
        `coach_time_zone`,
        JSON.stringify(result.configuration.time_zone)
      );
      const coachObject = result.coach_array.find(
        (coach) => coach.coach_id === dynamicCoachId
      );
      const navItems = coachObject.nav_items;
      localStorage.setItem("navItems", JSON.stringify(navItems));
      localStorage.setItem(
        "defaulLanguagae",
        JSON.stringify(result?.configuration?.default_language)
      );
      // let tempDefaultContentObj = result?.coach_static_content;
      // tempDefaultContentObj = {
      //   ...tempDefaultContentObj,
      //   analytics: {
      //     overview: {
      //       title: "Overview",
      //       refresh_icon_tooltip: "Refresh Analytics",
      //       total_user_tab: "Total User",
      //       total_user_tooltip: "Total Users",
      //       active_user_tab: "Active User",
      //       active_user_tooltip: "Active Users",
      //       new_user_tab: "New User",
      //       new_user_tooltip: "New Users",
      //       average_engagement_time_tab: "Average Engagement Time",
      //       average_engagement_time_tooltip: "Average Engagement Time",
      //       filter_seven_days: "7 Days",
      //       filter_thirty_days: "30 Days",
      //       filter_three_months: "Three Months",
      //       filter_six_months: "Six Months",
      //       filter_last_year: "Last Year",
      //       filter_custom_date: "Custom Date",
      //     },
      //     realtime_report: {
      //       title: "Realtime Report",
      //       refresh_icon_tooltip: "Refresh Analytics",
      //       last_five_minutes_tab: "Active Users In Last 5 Minutes",
      //       last_thirty_minutes_tab: "Active Users In Last 30 Minutes",
      //       events_tab_title: "Event Counts",
      //       events_name: "Events Name",
      //       events_counts: "Event Counts",
      //       per_minutes_tab_title: "Active Users per Minute",
      //       per_minutes_tab_minute_ago_text: "min ago",
      //       per_minutes_tooltip_text: "Active User",
      //       world_map_tooltip_text: "Active User",
      //     },
      //     events: {
      //       title: "Events",
      //       refresh_icon_tooltip: "Refresh Analytics",
      //       filter_button: "Filter",
      //       filter_drawer_filter_heading: "Filter",
      //       filter_chip_heading: "Filtered By",
      //       filter_chip_clear_all: "Clear All",
      //       table_event_name: "Event Name",
      //       table_active_user: "Active User",
      //       table_new_user: "New User",
      //       table_engaged_session: "Engaged Sessions",
      //       table_engagement_rate: "Engagement Rate",
      //       table_average_engagement_time: "Average Engagement Time",
      //     },
      //     event_details: {
      //       title: "Event Detail",
      //       refresh_icon_tooltip: "Refresh Analytics",
      //       filter_button: "Filter",
      //       filter_drawer_filter_heading: "Filter",
      //       event_list: "Event List",
      //       filter_chip_heading: "Filtered By",
      //       filter_chip_clear_all: "Clear All",
      //       total_user_tab: "Total User",
      //       total_user_tooltip: "Total Users",
      //       active_user_tab: "Active User",
      //       active_user_tooltip: "Active Users",
      //       new_user_tab: "New User",
      //       new_user_tooltip: "New Users",
      //       average_engagement_time_tab: "Average Engagement Time",
      //       average_engagement_time_tooltip: "Average Engagement Time",
      //     },
      //     demographic_report: {
      //       title: "Demographic Report",
      //       refresh_icon_tooltip: "Refresh Analytics",
      //       filter_button: "Filter",
      //       filter_drawer_filter_heading: "Filter",
      //       filter_chip_heading: "Filtered By",
      //       filter_chip_clear_all: "Clear All",
      //       table_country: "Country",
      //       table_active_user: "Active User",
      //       table_new_user: "New User",
      //       table_engaged_session: "Engaged Sessions",
      //       table_engagement_rate: "Engagement Rate",
      //       table_average_engagement_time: "Average Engagement Time",
      //       world_map_tooltip_text: "Active User",
      //     },
      //     platform_details: {
      //       title: "Platform Details",
      //       refresh_icon_tooltip: "Refresh Analytics",
      //       filter_button: "Filter",
      //       filter_drawer_filter_heading: "Filter",
      //       filter_chip_heading: "Filtered By",
      //       filter_chip_clear_all: "Clear All",
      //       table_browser: "Browser",
      //       table_active_user: "Active User",
      //       table_new_user: "New User",
      //       table_engaged_session: "Engaged Sessions",
      //       table_engagement_rate: "Engagement Rate",
      //       table_average_engagement_time: "Average Engagement Time",
      //     },
      //     filter_drawer: {
      //       filter_drawer_start_date: "Start Date",
      //       filter_drawer_end_date: "End Date",
      //       filter_drawer_filter_button: "Filter",
      //       filter_drawer_clear_button: "Clear Filter",
      //     },
      //   },
      //   breadcrumbs: {
      //     ...tempDefaultContentObj.breadcrumbs,
      //     main_breadcrumbs: {
      //       ...tempDefaultContentObj.breadcrumbs.main_breadcrumbs,
      //       analytics: "Analytics",
      //     },
      //     analytics: {
      //       overview: "Overview",
      //       realtimeReport: "Realtime Report",
      //       events: "Events",
      //       event_detail: "Event Detail",
      //       demographic_report: "Demographic Report",
      //       platform_details: "Platform Details",
      //     },
      //     settings: {
      //       ...tempDefaultContentObj.breadcrumbs.settings,
      //       client_default_content: "Client Default Content",
      //     },
      //   },
      // };
      // setDefaultContent(tempDefaultContentObj);
      // localStorage.setItem(
      //   "defaultContent",
      //   JSON.stringify(tempDefaultContentObj)
      // );
      setDefaultContent(result?.coach_static_content);
      localStorage.setItem(
        "defaultContent",
        JSON.stringify(result?.coach_static_content)
      );
      if (
        result?.coach_nav_bar_content &&
        Object.keys(result?.coach_nav_bar_content).length != 0
      ) {
        localStorage.setItem(
          "navbarContent",
          JSON.stringify(result?.coach_nav_bar_content)
        );
        setNavbarContent(result?.coach_nav_bar_content);
      }
      if (
        result?.client_nav_bar_content &&
        Object.keys(result?.client_nav_bar_content).length != 0
      ) {
        localStorage.setItem(
          "clientNavbarContent",
          JSON.stringify(result?.client_nav_bar_content)
        );
        setClientNavbarContent(result?.client_nav_bar_content);
      }
      setDefaultLanguage(result?.configuration?.default_language);
      handleConsultantData(result);
      handleNavItmes(navItems);
      handleCoachId(result?.configuration.coach_id);
      handleCoachList(result.coach_array);
      handleGraphics(result?.configuration.coach_dummy_content);
      START_SOCKET_FORCEFULLY(result.coach._id);
      handleAdminTimeZone(result.configuration.admin_timezone);
      handleCoachTimeZone(result.configuration.time_zone);

      getCurrencyList();
      getCourseCurrencyList();
    }
  };

  const handleGetProjectData = async () => {
    const resp = await project_configuration_api(coachId);
    if (resp.code === 200) {
      localStorage.setItem(
        "project_data",
        JSON.stringify(resp.configuration.project_configuration)
      );
      if (
        resp.configuration.project_configuration.stripe_configuration
          .public_key ||
        resp.configuration.project_configuration.stripe_configuration
          .sandbox_public_key
      ) {
        setStripeKeysExist(true);
      }
      handleProjectData(resp.configuration.project_configuration);
    }
    return resp;
  };

  const getCurrencyList = async () => {
    let result = await currencyApi();
    if (result.code == 200) {
      setCurrencyList(result.currency_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getCourseCurrencyList = async () => {
    let result = await currencyApicourses();
    if (result.code == 200) {
      setCourseCurrencyList(result.currency_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const convertedTimeInUserTimeZone = (dateString, format = "") => {
    return get_date_with_user_time_zone(
      dateString,
      format ?? "YYYY-MM-DD HH:mm:ss",
      coachTimeZone,
      adminTimeZone
    );
  };

  const convertedTimeInUserTimeZoneFromNow = (dateString) => {
    return moment(
      get_date_with_user_time_zone(
        dateString,
        "YYYY-MM-DD HH:mm:ss",
        coachTimeZone,
        adminTimeZone
      )
    ).fromNow();
  };

  const convertedTimeInUserTimeZoneFromCalender = (dateString) => {
    return moment(
      get_date_with_user_time_zone(
        dateString,
        "YYYY-MM-DD HH:mm:ss",
        coachTimeZone,
        adminTimeZone
      )
    ).calendar();
  };

  function getNavbarContent(navItem) {
    let label = navItem?.option_label;
    if (navbarContent && navbarContent[navItem?.option_value]) {
      label = navbarContent[navItem.option_value];
    }
    return label;
  }

  const getClientNavbarContent = (navItem) => {
    let label = navItem?.option_label;
    if (clientNavbarContent && clientNavbarContent[navItem?.option_value]) {
      label = clientNavbarContent[navItem?.option_value];
    }
    return label;
  };

  const collection = {
    coachList,
    setCoachList,
    handleCoachList,
    navItems,
    setNavItems,
    handleNavItmes,
    dynamicCoachId,
    setDynamicCoachId,
    handleDynamicCoach,
    consultantData,
    handleConsultantData,
    handleRemoveConsultantMetaData,
    generalData,
    projectData,
    handleProjectData,
    domainData,
    handleDomainData,
    handleGetProjectData,
    stripeKeysExist,
    setStripeKeysExist,
    handleGeneralData,
    handleCoachId,
    coachId,
    coachInfo,
    handleCoachInfo,
    adminTimeZone,
    setAdminTimeZone,
    coachTimeZone,
    setCoachTimeZone,
    handleCoachTimeZone,
    handleSetUserInfo,
    userInfo,
    getUserInfoDetails,
    teamCoachId,
    getProfile,
    getCardDetail,
    consultantId,
    teamType,
    memberType,
    setUserInfo,
    consultantInfo,
    cardDetail,
    sandboxCachCard,
    handleSetCard,
    handleSetCoachCard,
    setCardDetail,
    setSandboxCoachCard,
    handleConsultantInfo,
    // currency List
    currencyList,
    setCurrencyList,
    courseCurrencyList,
    setCourseCurrencyList,

    getCurrencyList,
    getCourseCurrencyList,
    handleAdminTimeZone,
    convertedTimeInUserTimeZoneFromNow,
    convertedTimeInUserTimeZoneFromCalender,
    convertedTimeInUserTimeZone,
    socket,
    START_SOCKET_FORCEFULLY,
    STOP_SOCKET_FORCEFULLY,
    openCardModal,
    setOpenCardModal,
    stripePaymentMode,
    setStripePaymentMode,
    handleGraphics,
    coachGraphics,
    open,
    setOpen,
    defaultContent,
    setDefaultContent,
    isLnaguageChange,
    setIsLanguageChange,
    defaulLanguagae,
    setDefaultLanguage,
    languageListing,
    setLanguageListing,
    getCoachLanguageListing,
    isLanguageUpdating,
    setIsLanguageUpdating,
    getNavbarContent,
    navbarContent,
    getClientNavbarContent,
  };

  useEffect(() => {
    // console.log(socket, "--socket");
    if (
      userInfo?._id &&
      !isConnected &&
      typeof socket === "object" &&
      Object.keys(socket).length > 0
    ) {
      socket.emit("live_event_room", userInfo._id);
      setIsConnected(true);
    }
    return () => {};
  }, [userInfo, isConnected]);

  // useEffect(() => {
  //   userInfo.is_executive = true;
  // }, [userInfo]);
  // console.log(socket, "i am socket");
  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
