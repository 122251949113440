import { invokeApi } from "../../bl_libs/invokeApi";

// const localStorage.getItem("token") =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MWZiNjg0MzFiOTIxZDM1NjQwNmQ4MmQiLCJsb2dpbl9ieSI6ImFkbWluX3VzZXIiLCJpYXQiOjE2NjY2MDYzMTJ9.DBr2kZVj1cgEJzqQVbZzBAXoH-Ptu4hvSQldanuApgE";

export const paymentPlansListBySalePageApi = async (id) => {
  const requestObj = {
    path: `api/payment_plan/list_payment_plan_by_consultant/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const paymentPlansListApi = async (id) => {
  const api_path = `api/payment_plan/list_payment_plan_by_consultant/${id}`;

  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const paymentPlansListApiforCoach = async (id) => {
  const api_path = `api/pages_paymnet_plan/get_pages_payment_plan_against_coach_id/${id}`;

  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const automatePaymentPlansListApi = async (id) => {
  const api_path = `api/payment_plan/get_payment_plan/${id}`;

  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const paymentPlansSubscribersListing = async (
  id,
  page,
  limit,
  filterName,
  data
) => {
  const api_path = `api/payment_plan/get_payment_plan_subscribers/${id}/?page=${page}&limit=${limit}&search=${
    filterName ? filterName : ""
  }`;
  const requestObj = {
    path: api_path,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const page_group_listing = async (id) => {
  const api_path = `api/page_group/list_published_page_group/${id}`;
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const automationDataApi = async (id) => {
  const requestObj = {
    path: `api/automation/get_all_data_for_automation/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const automationDeleteApi = async (data) => {
  const requestObj = {
    path: `api/automation/delete_automation`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};
export const getFullAutomationDataApi = async (id) => {
  const requestObj = {
    path: `api/automation/get_automation/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const getPageAutomation = async (id) => {
  const requestObj = {
    path: `api/automation/get_automation_by_page_group/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const addAutomationApi = async (data) => {
  const requestObj = {
    path: `api/automation/add_automation`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const detailPaymentPlanApi = async (id) => {
  const requestObj = {
    path: `api/payment_plan/detail_payment_plan/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const currencyApi = async (id) => {
  const requestObj = {
    path: `api/app_setting/list_currency_delegate`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const currencyApicourses = async (id) => {
  const requestObj = {
    path: `api/app_setting/list_course_currency`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editPaymentPlanApi = async (data, id) => {
  const requestObj = {
    path: `api/payment_plan/edit_payment_plan/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editAutomatePaymentPlanApi = async (data, id) => {
  const requestObj = {
    path: `api/payment_plan/edit_payment_plan/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addPaymentPlanApi = async (data) => {
  const requestObj = {
    path: `api/payment_plan/add_payment_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addAutomatePaymentPlanApi = async (data) => {
  const requestObj = {
    path: `api/payment_plan/add_payment_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deletePaymentPlanApi = async (id) => {
  const requestObj = {
    path: `api/payment_plan/delete_payment_plan/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteAutomatePaymentPlanApi = async (id) => {
  const requestObj = {
    path: `api/payment_plan/delete_payment_plan/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AccessApi = async (data) => {
  const requestObj = {
    path: `api/payment_plan/assign_to_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// Discount API's

export const manage_plan_discount = async (data) => {
  const requestObj = {
    path: `api/payment_plan/update_discount`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
// Payment plans upgraded flow
export const payment_plan_list = async (id) => {
  const api_path = `api/pages_paymnet_plan/get_all_pages_payment_plan_against_coach/${id}`;
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_payment_plan = async (data) => {
  const requestObj = {
    path: `api/pages_paymnet_plan/add_pages_payment_plan_without_page`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const edit_payment_plan = async (data, id) => {
  const requestObj = {
    path: `api/pages_paymnet_plan/edit_pages_payment_plan_without_page/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const delete_payment_plan = async (id) => {
  const requestObj = {
    path: `api/pages_paymnet_plan/delete_pages_payment_plan_against_coach/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// Manage Web Pages of Payment Plan

export const web_pages_list_for_payment_plan = async (id) => {
  const api_path = `api/page_group/get_page_group_listing/${id}`;
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_web_pages_for_payment_plan = async (data) => {
  const requestObj = {
    path: `api/pages_paymnet_plan/assign_payment_plan_to_pages`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// plan Access

export const update_access_for_payment_plan = async (data) => {
  const requestObj = {
    path: `api/pages_paymnet_plan/assign_access_to_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// upsell plan

export const upsell_plans_for_payment_plan = async (data) => {
  const requestObj = {
    path: `api/pages_paymnet_plan/assign_upsell_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// plan discount

export const update_discount_for_payment_plan = async (data) => {
  const requestObj = {
    path: `api/pages_paymnet_plan/update_discount_against_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// Active  Campaign

export const update_active_campaign_for_payment_plan = async (data) => {
  const requestObj = {
    path: `api/pages_paymnet_plan/update_active_campaign`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// Mailchimp Update

export const update_mailchimp_for_payment_plan = async (data) => {
  const requestObj = {
    path: `api/pages_paymnet_plan/update_mail_chimp`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};